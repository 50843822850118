import * as React from "react"
//import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
//import * as styles from "../components/index.module.css"
import sliderBackground1 from "../images/slider-01.jpg"
import sliderBackground2 from "../images/slider-02.jpg"
import sliderBackground3 from "../images/slider-03.jpg"

import Carousel from 'react-bootstrap/Carousel';

function carouselHeader() {
	return (
		<Carousel>
			<Carousel.Item interval={3000}>
				<img
					className="d-block w-100"
					src={sliderBackground1}
					alt="First slide"
				/>

				<Carousel.Caption>
					<h3>Người cộng sự đáng tin cậy</h3>
					<p>Chúng tôi phát triển, cung cấp, và triển khai các dự án phức tạp để đơn giản hoá vấn đề của bạn.</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item interval={3000}>
				<img
					className="d-block w-100"
					src={sliderBackground2}
					alt="Second slide"
				/>
				<Carousel.Caption>
					<h3>Cải thiện hiệu quả doanh nghiệp</h3>
					<p>Chúng tôi hướng đến cung cấp những giá trị hữu hình cho doanh nghiệp, không chỉ tập trung giải quyết các vấn đề ngắn hạn mà còn hướng tới sự bền vững dài hạn.</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item interval={3000}>
				<img
					className="d-block w-100"
					src={sliderBackground3}
					alt="Third slide"
				/>

				<Carousel.Caption>
					<h3>Đem lại sư an tâm cho doanh nghiệp</h3>
					<p>
						Chúng tôi tư vấn và triển khai giải pháp an toàn thông tin cho doanh nghiệp.
					</p>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>
	);
}

const IndexPage = () => (
	<Layout>
		<Seo title="Home" />
		<header className="slider-main">
			{carouselHeader()}
		</header>

		<div className="container">

			<div className="about-main">
				<div className="row">
					<div className="col-lg-6">
						<h2>Về chúng tôi</h2>
						<p>PhuongLong là nơi hội tụ cùng phát triển của đội ngũ có lý tưởng tốt đẹp, có năng lực vững vàng, khao khát chung tay tạo nên những nền tảng công nghệ để phục vụ cho sự phát triển của doanh nghiệp, cộng đồng và đất nước.</p>
						<p>Môi trường làm việc của chúng tôi là tập trung, linh hoạt và hiệu quả cao; Văn hóa chúng tôi là đề cao tinh thần tự cường, tuân thủ kỷ luật, ghi nhận xứng đáng, tôn trọng lẫn nhau; đòi hỏi Nhân viên chúng tôi phải luôn nỗ lực để tạo ra giá trị bền vững, nâng cấp bản thân liên tục, phấn đấu trở thành tấm gương truyền động lực, lan tỏa giá trị tốt đẹp tới cộng đồng, xã hội.</p>
					</div>
					<div className="col-lg-6">
						<StaticImage src="../images/about-img.jpg" loading="eager" quality={95} formats={["auto", "webp", "avif"]} alt="" />
					</div>
				</div>

			</div>
		</div>

		<div id="services" className="services-bar">
			<div className="container">
				<h1 className="py-4">Các dịch vụ tốt nhất của chúng tôi </h1>
				{/*-- Services Section */}
				<div className="row">
					<div className="col-lg-4 mb-4">
						<div className="card h-100">
							<div className="card-img">

								<StaticImage src="../images/services-img-01.jpg" className="img-fluid" loading="eager" quality={95} formats={["auto", "webp", "avif"]} alt="" />
							</div>
							<div className="card-body">
								<h4 className="card-header"> Phát triển ứng dụng theo yêu cầu </h4>
								<p className="card-text">Với nhiều năm kinh nghiệm trong lĩnh vực lập trình phần mềm và phân tích thiết kế cơ sở dữ liệu, chúng tôi đã cho ra đời khá nhiều phần mềm, đáp ứng hầu hết và hỗ trợ khá tốt công việc của các doanh nghiệp. Với ưu thế là mã nguồn riêng, bảo mật mang đến sự an tâm thật sự cho khách hàng của mình.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 mb-4">
						<div className="card h-100">
							<div className="card-img">
								<StaticImage src="../images/services-img-04.jpg" className="img-fluid" loading="eager" quality={95} formats={["auto", "webp", "avif"]} alt="" />

							</div>
							<div className="card-body">
								<h4 className="card-header"> Tư vấn về giải pháp doanh nghiệp </h4>
								<p className="card-text">Chúng tôi hỗ trợ lựa chọn và triển khai các giải pháp phù hợp nhất, đảm bảo hiệu quả và hiệu suất hoạt động của doanh nghiệp.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 mb-4">
						<div className="card h-100">
							<div className="card-img">
								<StaticImage src="../images/services-img-02.jpg" className="img-fluid" loading="eager" quality={95} formats={["auto", "webp", "avif"]} alt="" />

							</div>
							<div className="card-body">
								<h4 className="card-header">Bảo mật hệ thống thông tin</h4>
								<p className="card-text">Giúp các doanh nghiệp hiểu rõ những thách thức trên không gian mạng, thích ứng và đối phó với những rủi ro vốn có trong môi trường kinh doanh, và bảo vệ các tài sản quan trọng nhất đối với thương hiệu.</p>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

	</Layout>
)

export default IndexPage
